import { bindable, customElement, inject, noView } from "aurelia-framework";
import { render, unmountComponentAtNode } from "react-dom";
import React from "react";
import {ErrorDisplay} from "../order/offer-configuration/components/error-display";

@noView
@inject(Element)
@customElement("error-display")
export default class ErrorDisplayAurelia {
    @bindable() errors;

    constructor(container) {
        this.container = container;
    }

    attached() {
        this.render();
    }

    bind() {
        this.attached();
    }

    async render() {
        render(<ErrorDisplay errors={this.errors} />, this.container);
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
