import {inject} from "aurelia-framework";
import {Client} from "../api/client";
import {DialogController} from 'aurelia-dialog';
import {FlashService} from "../flash/flash-service";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {ConfigurationLoader} from "../form/loader/configuration-loader";

@inject(Client, DialogController, FlashService, ConfigurationLoader, FormServiceFactory)
export class ProductSearch {

    form;

    constructor(client, dialogController, flash, configurationLoader, formServiceFactory) {
        this.client = client;
        this.dialogController = dialogController;
        this.flash = flash;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;

        this.submitHandler = this.submit.bind(this);
    }

    onSelectionChangedCallback() {
        return (selectedItems) => {
            this.selectedItems = selectedItems;
        }
    }

    async activate(context) {
        console.log('context', context);
        this.data = context.data;
        this.title = context.title;
        this.order = context.order;
        this.participants = context.participants;

        this.selectModelId = context.selectModelId;

        this.data = {
            participants: context.participants,
            order: {
                id: context.order.id,
                modelId: context.order.modelId,
            },
            modelIdentifier: context.endpoint,
            //Todo should be participant count here
            amount: this.order.b2bParticipants ?? this.participants.length
        };

        this.selectedItems = [];
    }

    async submit({object}) {
        this.loading = true;
        this.searchId = null;

        try {
            let data = await this.client.post('provider/search', object);

            this.errors = data.data.errors;
            this.searchId = data.data.searchId;

            this.loading = false;
        } catch (err) {
            this.loading = false;
            this.errors = null;
            this.searchId = null;

            let error = null;

            if (err && err.data) {
                if (err.data.localizedMessage) {
                    error = err.data.localizedMessage;
                } else if (err.data.message) {
                    error = err.data.message;
                }
            }

            this.flash.error(error || 'Ein unbekannter Fehler ist aufgetreten.');
        }
    }

    ok() {
        if (this.selectedItems.length < 1) {
            this.flash.error('Bitte ein Suchergebnis auswählen');
            return;
        }

        this.dialogController.ok(
            this.selectedItems.map((item) => {
                return {
                    id: item.id,
                    modelId: item.modelId,
                    label: item?.label,
                };
            })
        );
    }
}
